










import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'MOrganizers',
  components: {
    MManageAccess: () => import('@/components/molecules/settings/m-manage-access.vue')
  },
  props: {
    userType: {
      required: true,
      type: String,
      default: ''
    },
    programId: {
      required: true,
      type: String,
      default: ''
    },
    isDraft: {
      type: Boolean,
      required: false
    }
  }
});
